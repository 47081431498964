.maintitle{
    h1{
        margin: 0px;
        padding: 0px;
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 120px;
        line-height: 120px;
        color: var(--data-color-primary);

    }
}
body{
    margin: 0px 20px 0px 20px;
}





@media only screen and (max-width: 926px) {

.bottominformation{
    margin-top: 80px;
    .wrapperbox{
        margin-top: 50px;
        margin-bottom: 50px;
    }
}


.wrapperbox{
    margin-top: 20px;
    padding: 10px;
    border-left: solid;
    border-color: var(--data-color-secondary);
    width: 100%;
    .title{
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: var(--data-color-primary);
    }
    .text{
        p{
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
        }
        ul{
            padding: 0px;
            margin: 0px;
            list-style-type: none;
            .title{
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 13px;
            }
            .text{
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                
            }
        }
    }
}


.maingrid{
    padding-top: 180px;
    .imagewrapper{
        img{
            width: 100%;
        }
    }
}
}

@media only screen and (min-width: 926px) {
.maingrid{
    height: 100vh !important;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    width: 100%;
    display: grid;
    row-gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);

    div{
        overflow: hidden;
    }

    .maintitle{
        grid-column-start: 2;
        grid-column-end: 6;
        grid-row-start: 5;
        grid-row-end: 9;
        display: flex;
        flex-direction: column;
        justify-content: end;
        flex-grow: 1;
        overflow: hidden;
        h1{
            margin: 0px;
            padding: 0px;
            font-family: Oswald;
            font-style: normal;
            font-weight: normal;
            font-size: 120px;
            line-height: 120px;
            color: var(--data-color-primary);

        }
    }
    .bottominformation{
        padding: 10px 0px 10px 0px;
        grid-column-start: 2;
        grid-column-end: 13;
        grid-row-start: 10;
        grid-row-end: 13;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .wrapperbox{
            padding: 10px;
            border-left: solid;
            border-color: var(--data-color-secondary);
            width: 400px;
            .title{
                font-family: Oswald;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                color: var(--data-color-primary);
            }
            .text{
                p{
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 15px;
                }
                ul{
                    padding: 0px;
                    margin: 0px;
                    list-style-type: none;
                    .title{
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 13px;
                    }
                    .text{
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
    .imagewrapper{
        display: flex;
        justify-content: center;
        grid-column-start: 6;
        grid-column-end: 13;
        grid-row-start: 2;
        grid-row-end: 9;

        img{
            height: 100%;
        }
    }
    
}

}