@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:wght@300;400;500&display=swap');

:root{ 
    --data-color-primary: #363946; 
    --data-color-secondary: #70A288; 
}

body{
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
}

h1{
    font-family: 'Oswald', sans-serif;
    font-size: 50px;
}
h2{
    font-family: 'Oswald', sans-serif;
    color: var(--data-color-secondary);
}
h3{
    color: var(--data-color-secondary);
    padding: 0px;
    margin: 0px;
}



.overlay{
    height: 100vh;
    opacity: 1;
    width: 100%;
    background-color: var(--data-color-primary);
    position: fixed;
    z-index: 200;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.container{
    padding: 0px 100px 0px 100px;
}
@media only screen and (max-width: 600px) {
    .container{
        padding: 0px 20px 0px 20px;
    }
  }


  .header{
    margin: 100px 0px 0px 100px;
    position: absolute;
    padding: 0px;
}

@media only screen and (max-width: 600px) {
    .header{
        margin: 40px 0px 0px 30px;
        position: absolute;
        padding: 0px;
    }
  }
header{
    overflow: hidden;
    .title{
        color: var(--data-color-primary);
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        a{
            text-decoration: none;
            color: var(--data-color-primary)
        }
    }
    ul{
        
        margin: 0px;
        padding: 10px;
        border-left: solid;
        border-color: var(--data-color-secondary);
        list-style-type: none;
        li{
            a{
                color: var(--data-color-secondary);
                text-decoration: none;
                transition: ease-in-out 100ms;
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 15px;
            }:hover{
                color: #428e66;
                transform: translate(10px, 10px, 10px)
            }
        }
    }
}

