@import "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:wght@300;400;500&display=swap";
:root {
  --data-color-primary: #363946;
  --data-color-secondary: #70A288;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
}
h1 {
  font-family: "Oswald", sans-serif;
  font-size: 50px;
}
h2 {
  font-family: "Oswald", sans-serif;
  color: var(--data-color-secondary);
}
h3 {
  color: var(--data-color-secondary);
  padding: 0px;
  margin: 0px;
}
.overlay {
  height: 100vh;
  opacity: 1;
  width: 100%;
  background-color: var(--data-color-primary);
  position: fixed;
  z-index: 200;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.container {
  padding: 0px 100px 0px 100px;
}
@media only screen and (max-width: 600px) {
  .container {
    padding: 0px 20px 0px 20px;
  }
}
.header {
  margin: 100px 0px 0px 100px;
  position: absolute;
  padding: 0px;
}
@media only screen and (max-width: 600px) {
  .header {
    margin: 40px 0px 0px 30px;
    position: absolute;
    padding: 0px;
  }
}
header {
  overflow: hidden;
}
header .title {
  color: var(--data-color-primary);
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}
header .title a {
  text-decoration: none;
  color: var(--data-color-primary);
}
header ul {
  margin: 0px;
  padding: 10px;
  border-left: solid;
  border-color: var(--data-color-secondary);
  list-style-type: none;
}
header ul li a {
  color: var(--data-color-secondary);
  text-decoration: none;
  transition: ease-in-out 100ms;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
}
header ul li :hover {
  color: #428e66;
  transform: translate(10px, 10px, 10px);
}
.maintitle h1 {
  margin: 0px;
  padding: 0px;
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 120px;
  line-height: 120px;
  color: var(--data-color-primary);
}

body {
  margin: 0px 20px 0px 20px;
}

@media only screen and (max-width: 926px) {
  .bottominformation {
    margin-top: 80px;
  }
  .bottominformation .wrapperbox {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .wrapperbox {
    margin-top: 20px;
    padding: 10px;
    border-left: solid;
    border-color: var(--data-color-secondary);
    width: 100%;
  }
  .wrapperbox .title {
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: var(--data-color-primary);
  }
  .wrapperbox .text p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
  }
  .wrapperbox .text ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
  }
  .wrapperbox .text ul .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
  }
  .wrapperbox .text ul .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }

  .maingrid {
    padding-top: 180px;
  }
  .maingrid .imagewrapper img {
    width: 100%;
  }
}
@media only screen and (min-width: 926px) {
  .maingrid {
    height: 100vh !important;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    width: 100%;
    display: grid;
    row-gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
  }
  .maingrid div {
    overflow: hidden;
  }
  .maingrid .maintitle {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 5;
    grid-row-end: 9;
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex-grow: 1;
    overflow: hidden;
  }
  .maingrid .maintitle h1 {
    margin: 0px;
    padding: 0px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 120px;
    line-height: 120px;
    color: var(--data-color-primary);
  }
  .maingrid .bottominformation {
    padding: 10px 0px 10px 0px;
    grid-column-start: 2;
    grid-column-end: 13;
    grid-row-start: 10;
    grid-row-end: 13;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .maingrid .bottominformation .wrapperbox {
    padding: 10px;
    border-left: solid;
    border-color: var(--data-color-secondary);
    width: 400px;
  }
  .maingrid .bottominformation .wrapperbox .title {
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: var(--data-color-primary);
  }
  .maingrid .bottominformation .wrapperbox .text p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
  }
  .maingrid .bottominformation .wrapperbox .text ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
  }
  .maingrid .bottominformation .wrapperbox .text ul .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
  }
  .maingrid .bottominformation .wrapperbox .text ul .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
  .maingrid .imagewrapper {
    display: flex;
    justify-content: center;
    grid-column-start: 6;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 9;
  }
  .maingrid .imagewrapper img {
    height: 100%;
  }
}
/*# sourceMappingURL=impressum.78893eb3.css.map */
